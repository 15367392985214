import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: "light",
};

const muiSlice = createSlice({
  initialState,
  name: "mui",
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
  },
});

export const muiAction = muiSlice.actions;

const muiReducer = muiSlice.reducer;
export default muiReducer;
