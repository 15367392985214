import React, { useEffect, useState } from "react";
import AuthFooter from "./AuthFooter";
import { Link } from "react-router-dom";
import { Form, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";

//^ images
import Logo from "../../images/436.png";
import LogoDark from "../../images/436.png";

//^ layouts
import Head from "../../layout/head/Head";

//^ controller
import { resendLinkRequest, resetLinkRequest } from "../../controller/auth_controller";

//^ post http request
import { postLogin } from "../../http/post-api";

//^ component
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Icon, PreviewCard } from "../../components/Component";

//^ mui
import { Button, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import LoginTwoFactOtpVerification from "./login-two-fact-otp-verification/LoginTwoFactOtpVerification";

const Login = () => {
  //^ loading states
  const [loading, setLoading] = useState(false);
  const [loadingElement, setLoadingElement] = useState(false);

  const theme = useTheme();

  //^ success states
  const [passState, setPassState] = useState(false);
  const [successVal, setSuccessVal] = useState("");

  //^ error states
  const [validationErrors, setValidationErrors] = useState([]);
  const [errorVal, setError] = useState("");
  const [isVerificationRequired, setIsVerificationRequired] = useState(false);

  //^ twoFact states
  const [twoFact, setTwoFact] = useState(false);
  const [twoFactLoginRes, SetTwoFactLoginRes] = useState(undefined);

  //^ other states
  const [username, setUsername] = useState("");

  useEffect(() => {
    const cookieValue = Cookies.get("authToken");
    if (cookieValue) {
      window.location = process.env.REACT_APP_DEFAULT_REDIRECT_URL;
    }
  }, []);

  const forgotClick = async () => {
    let username = document.getElementById("username").value;
    if (username.length > 0) {
      setLoadingElement("forgot");
      setLoading(true);
      let resendLinkRes = await resetLinkRequest(username);
      if (resendLinkRes.status) {
        setSuccessVal(resendLinkRes.message);
        setError(false);
      } else {
        setSuccessVal("");
        setError(resendLinkRes.message);
      }
    } else {
      setError("Enter username to send reset password link");
      setSuccessVal("");
    }
    setLoadingElement("");
    setLoading(false);
  };

  //^ post login mutation query
  const {
    isPending: postLoginIsPending,
    mutate: postLoginMutate,
    reset: postLoginReset,
  } = useMutation({
    mutationKey: ["post-login"],
    mutationFn: postLogin,
    onSuccess: (data) => {
      setLoadingElement("");
      setLoading(false);

      if (data.status === true) {
        setSuccessVal(data.message);
        setError(false);

        if (!data.data.two_fa) {
          setTwoFact(data.data.two_fa);
          const now = new Date();
          const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

          Cookies.set("authToken", data.data.authToken, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          Cookies.set("username", data.data.username, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          Cookies.set("email", data.data.email, { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: expires });

          const currentURL = Cookies.get("currentURL");

          const role = data.data.role.id;
          if (currentURL) {
            window.location = currentURL;
          }else{ 
            if (role == '2') {
              window.location = process.env.REACT_APP_SITE_SETTING_URL;
            } else {
              window.location = process.env.REACT_APP_DEFAULT_REDIRECT_URL;
            }
          }
        } else {
          SetTwoFactLoginRes(data);
          setTwoFact(data.data.two_fa);
        }
      } else {
        setError(data.message);
        setSuccessVal("");

        if (data.errors) setValidationErrors(data.errors);
        else setValidationErrors([]);

        if (data?.data?.verifyRequired && data?.data?.verifyRequired === "1") {
          setIsVerificationRequired(true);
        } else {
          setIsVerificationRequired(false);
        }
      }

      postLoginReset();
    },
  });

  const onFormSubmit = async (formData) => {
    const trimmedNameValue = formData.name.trim();
    const trimmedPasscodeValue = formData.passcode.trim();

    setError("");

    if (!trimmedNameValue.length > 0 || !trimmedPasscodeValue.length > 0) {
      setError("Invalid credentials");
      setSuccessVal("");
      return false;
    }

    setLoading(true);
    setLoadingElement("login");
    setUsername(formData.name);

    postLoginMutate({ password: formData.passcode, username: formData.name });
  };

  const resendLink = async () => {
    let username = document.getElementById("username").value;
    if (username.length > 0) {
      setLoadingElement("resend");
      setLoading(true);
      let resendLinkRes = await resendLinkRequest(username);
      if (resendLinkRes.status) {
        setSuccessVal(resendLinkRes.message);
        setError(false);
      } else {
        setSuccessVal("");
        setError(resendLinkRes.message);
      }
    } else {
      setError("Enter username to resend activation link");
      setSuccessVal("");
    }
    setLoadingElement("");
    setLoading(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      {twoFact ? (
        <LoginTwoFactOtpVerification
          mobile={twoFactLoginRes?.data?.phone}
          email={twoFactLoginRes?.data?.email}
          type={twoFactLoginRes?.data?.two_fact_auth}
          username={username}
        />
      ) : (
        <>
          <Head title="Login" />
          <Block className="nk-block-middle nk-auth-body  wide-xs">
            <div className="brand-logo pb-2 text-center">
              <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
                <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
              </Link>
            </div>

            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h4">Login</BlockTitle>
                  <BlockDes className="d-none">
                    <p>Access {process.env.REACT_APP_PROJECT_NAME} using your email or username and password.</p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>
              {successVal && (
                <div className="mb-3">
                  <Alert color="success" className="alert-icon">
                    <Icon name="alert-circle" />
                    {successVal}
                  </Alert>
                </div>
              )}
              {errorVal && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    <Icon name="alert-circle" /> {errorVal}
                    {validationErrors.length > 0 && (
                      <ul style={{ listStyle: "circle" }}>
                        {validationErrors.map((error, key) => (
                          <li key={key}>{error}</li>
                        ))}
                      </ul>
                    )}
                  </Alert>
                </div>
              )}
              <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="default-01">
                      Email or Username
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="username"
                      tabIndex={1}
                      {...register("name", { required: "This field is required" })}
                      placeholder="Enter your email address or username"
                      className="form-control-lg bg-transparent border form-control"
                    />
                    {errors.name && <span className="invalid">{errors.name.message}</span>}
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <a
                      href="#password"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setPassState(!passState);
                      }}
                      className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                    >
                      <Icon name="eye" className="passcode-icon icon-show"></Icon>

                      <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                    </a>
                    <input
                      type={passState ? "text" : "password"}
                      id="password"
                      tabIndex={2}
                      {...register("passcode", { required: "This field is required" })}
                      placeholder="Enter your password"
                      className={`form-control-lg bg-transparent border form-control ${
                        passState ? "is-hidden" : "is-shown"
                      }`}
                    />
                    {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-label-group">
                    <button
                      type="button"
                      className="link link-primary link-sm"
                      onClick={forgotClick}
                      disabled={loading && loadingElement === "forgot"}
                    >
                      {loading && loadingElement === "forgot" ? "Sending Reset Password Link" : "Forgot Password?"}
                    </button>
                    {isVerificationRequired && (
                      <button
                        type="button"
                        className="link link-primary link-sm"
                        style={{ cursor: `${loading && loadingElement === "resend" ? "wait" : "pointer"}` }}
                        onClick={resendLink}
                        disabled={loading && loadingElement === "resend"}
                      >
                        {loading && loadingElement === "resend"
                          ? "Resending Activation Link"
                          : "Resend Activation Link"}
                      </button>
                    )}
                  </div>
                  <Stack>
                    <Button
                      variant={"contained"}
                      size="large"
                      type="submit"
                      color="primary"
                      disabled={postLoginIsPending}
                      startIcon={
                        (loading && loadingElement === "Login") || postLoginIsPending ? (
                          <Stack alignItems={"center"} justifyContent={"center"}>
                            <Spinner
                              style={{ borderWidth: "2px" }}
                              size="sm"
                              color={`${theme.palette.mode === "dark" ? "light" : "inherit"}`}
                            />
                          </Stack>
                        ) : (
                          ""
                        )
                      }
                    >
                      Login
                    </Button>
                  </Stack>
                </div>
              </Form>
              <div className="form-note-s2 text-center pt-4">
                New on our platform? <Link to={`${process.env.PUBLIC_URL}/register`}>Create an account</Link>
              </div>
              <div className="text-center pt-4 pb-3 d-none">
                <h6 className="overline-title overline-title-sap">
                  <span>OR</span>
                </h6>
              </div>
              <ul className="nav justify-center gx-4 d-none">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#socials"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    Facebook
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#socials"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    Google
                  </a>
                </li>
              </ul>
            </PreviewCard>
          </Block>
        </>
      )}
      <AuthFooter />
    </>
  );
};
export default Login;
